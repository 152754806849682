import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { theme } from "../../Theme";
import Avatar from "../../assets/avatar.png";
import ArXivLogo from "../../assets/arxiv-logo.png";
import Socials from "../Socials";
import { Link } from "rebass";
import { FaGithub, FaFilePdf } from "react-icons/fa";
import content from "./RawContent";

const hue = keyframes`
    from {
        -webkit-filter: hue-rotate(0deg);
    }
    to {
        -webkit-filter: hue-rotate(45deg);
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 740px;
    gap: 42px;
    padding: 22px 0;
    @media screen and (max-width: 555px) {
        flex-direction: column;
        gap: 22px;
        max-width: 100%;
    }
`;

const Title = styled.span`
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0.01rem;
    -webkit-animation: ${hue} 2s infinite linear;
    background: ${theme().grad4};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation-direction: alternate;
`

const ProfileSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ProfileSectionAlt = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        width: 180px;
        height: 180px;
        border-radius: 90px;
    }
`

const ItemList = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    gap: 22px;
    width: 100%;
`

const Divider2 = styled.div`
    height: 12px;
`

const ContentDisplay = ({ data, contentType, darkMode }) => {

  return (
    <div style={{ display: "flex" }}>
      <div style={{
        display: "flex",
        background: theme(darkMode).bgBox,
        padding: "12px",
        borderRadius: "10px",
        border: `1px solid ${theme(darkMode).borderBox}`,
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        gap: "6px",
      }}>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "left",
        }}>
          <div style={{ display: "inline" }}>
            <span style={{ color: theme(darkMode).textColor }}>
              {data['text']}
            </span>
            <span style={{ color: theme(darkMode).titleColor }}>
              {" "}{data['title']}
            </span>
            {contentType === "Publications"
              ? <span style={{ color: theme(darkMode).textColor, fontStyle: "italic" }}>
                {" "}{data['journal']}
              </span>
              : null}
          </div>
        </div>
        <div style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "left",
          gap: "8px",
        }}>
          {data['arxivLink'] !== "" ? <div>
            <Link href={`${data['arxivLink']}`} target="_blank" style={{ cursor: "pointer", textDecoration: "none" }}>
              <img src={ArXivLogo} height={18} color={theme(darkMode).iconColor} alt={"arxiv-logo"}/>
            </Link>
          </div>
            : null}
          {data['codeLink'] !== "" ? <div>
            <Link href={`${data['codeLink']}`} target="_blank" style={{ cursor: "pointer", textDecoration: "none" }}>
              <FaGithub size={22} color={theme(darkMode).iconColor} />
            </Link>
          </div>
            : null}
          {data['pdfLink'] !== "" ? <div>
            <Link href={`${data['pdfLink']}`} target="_blank" style={{ cursor: "pointer", textDecoration: "none" }}>
              <FaFilePdf size={22} color={theme(darkMode).iconColor} />
            </Link>
          </div>
            : null}
        </div>
      </div>
    </div>
  );
}

const Content = ({ darkMode }) => {

  const [section, setSection] = useState(1)

  return (
    <>
      <Container>
        <AvatarWrapper> <img src={Avatar} alt={"avatar"}/> </AvatarWrapper>
        <ProfileSection>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Title>{"Rahul Zalkikar"}</Title>
          </div>
          <Socials iconColor={theme(darkMode).iconColor} />
        </ProfileSection>
      </Container>
      <Container>
        <ProfileSectionAlt>
          <div style={{ display: "flex", flexDirection: "row", paddingBottom: "22px", width: "100%", justifyContent: "left", gap: "22px" }}>
            <span style={{
              color: section === 1
                ? theme(darkMode).textColor3
                : theme(darkMode).textColor2,
              fontWeight: 600,
              fontSize: "1rem",
              cursor: "pointer"
            }} onClick={() => {
              setSection(1)
            }}>
              {"PUBLICATIONS"}
            </span>
            <span style={{
              color: section === 2
                ? theme(darkMode).textColor3
                : theme(darkMode).textColor2,
              fontWeight: 600,
              fontSize: "1rem",
              cursor: "pointer"
            }} onClick={() => {
              setSection(2)
            }}>
              {"PROJECTS"}
            </span>
          </div>
        </ProfileSectionAlt>
      </Container>
      <Container>
        <ProfileSectionAlt>
          <Divider2 />
          {section === 1
            &&
            <>
              <div style={{ display: "flex", flexDirection: "row", paddingBottom: "22px", width: "100%", justifyContent: "left" }}>
                <span style={{ color: theme(darkMode).textColor, fontWeight: 600, fontSize: "1.4rem" }}>{"Publications"}</span>
              </div>
              <ItemList>
                {content["Publications"].map((item, index) => {
                  return (
                    <ContentDisplay data={item} contentType={"Publications"} darkMode={darkMode} />
                  );
                })}
              </ItemList>
            </>
          }
          {section === 2
            &&
            <>
              <div style={{ display: "flex", flexDirection: "row", paddingBottom: "22px", width: "100%", justifyContent: "left" }}>
                <span style={{ color: theme(darkMode).textColor, fontWeight: 600, fontSize: "1.4rem" }}>{"Projects"}</span>
              </div>
              <ItemList>
                {content["Projects"].map((item, index) => {
                  return (
                    <ContentDisplay data={item} contentType={"Projects"} darkMode={darkMode} />
                  );
                })}
              </ItemList>
            </>
          }
        </ProfileSectionAlt>
      </Container>
    </>
  );
};

export default Content;