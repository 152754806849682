import React from "react";
import styled from "styled-components";
import Header from "../Header";
import Content from "../Content";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height 100vh;
    padding: 42px 0;
`;

const PersonalSite = ({ darkMode, setDarkMode }) => {
  return (
    <Container>
      <Header darkMode={darkMode} setDarkMode={setDarkMode} />
      <Content darkMode={darkMode} />
    </Container>
  );
};

export default PersonalSite;