export const theme = (darkMode) => ({
  bgGlobal: (darkMode !== null && darkMode !== undefined && darkMode)
    ? "#070816"
    : "linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)",
  bgBox: (darkMode !== null && darkMode !== undefined && darkMode)
    ? "linear-gradient(150deg, #12121c 0%, #1f2b35 50%, #343e4f 100%)"
    : "linear-gradient(150deg, #fafafa 0%, #e0e4e8 50%, #c8d1d8 100%)",
  borderBox: (darkMode !== null && darkMode !== undefined && darkMode)
    ? "#343e4f"
    : "#c8d1d8",
  textColor: (darkMode !== null && darkMode !== undefined && darkMode)
    ? "white"
    : "black",
  textColor2: (darkMode !== null && darkMode !== undefined && darkMode)
    ? "grey"
    : "grey",
  textColor3: (darkMode !== null && darkMode !== undefined && darkMode)
    ? "lightblue"
    : "black",
  iconColor: (darkMode !== null && darkMode !== undefined && darkMode)
    ? "lightblue"
    : "black",
  titleColor: (darkMode !== null && darkMode !== undefined && darkMode)
    ? "#3399cc"
    : "#0073a8",
  grad4: "linear-gradient(to right, #25adea 0%, #a151ff 100%)",
})