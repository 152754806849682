const content = {
  "Publications": [
    {
      text: "Rahul Zalkikar and Kanchan Chandra. (2024).",
      title: "Measuring Social Biases in Masked Language Models by Proxy of Prediction Quality.",
      journal: "Under review.",
      arxivLink: "https://arxiv.org/abs/2402.13954",
      pdfLink: process.env.PUBLIC_URL + "/papers/BiasMLMs.pdf",
      codeLink: "https://github.com/zalkikar/mlm-bias",
      pdfColor: "grey",
    },
    {
      text: "Kanchan Chandra and Rahul Zalkikar. (2024).",
      title: "Shades of Majoritarianism: Explicit and Implicit Appeals in Campaign Messages.",
      journal: "Paper in progress.",
      arxivLink: "",
      pdfLink: "",
      codeLink: "",
      pdfColor: "grey",
    },
    {
      text: "Noah Kasmanoff and Rahul Zalkikar. (2021).",
      title: "Limitation Learning: Capturing Adverse Dialog with Generative Adversarial Imitation Learning.",
      journal: "",
      arxivLink: "",
      pdfLink: process.env.PUBLIC_URL + "/papers/LimitationLearning.pdf",
      codeLink: "https://github.com/zalkikar/limitation-learning",
      pdfColor: "grey",
    },
    {
      text: "Aren Dakessian, Guido Petri, and Rahul Zalkikar. (2020).",
      title: "Synthesizing baseball data with event prediction pretraining.",
      journal: "",
      arxivLink: "",
      pdfLink: process.env.PUBLIC_URL + "/papers/PositionTracking.pdf",
      codeLink: "",
      pdfColor: "grey",
    },
    {
      text: "Kevin Driscoll and Rahul Zalkikar. (2019).",
      title: "The Gender Binary of Computing: Challenging Sexism in Technology.",
      journal: "Culture Digitally.",
      arxivLink: "",
      pdfLink: "https://zalkikar.github.io",
      codeLink: "",
      pdfColor: "grey",
    },
  ],
  "Projects": [
    {
      text: "Boundary box creation using a GradCAM heat-map from a pre-trained image classification model (2020)",
      title: "Improving IoU for bbox creation with vision contouring from grad-CAM heatmap weighted by trained image classifier.",
      arxivLink: "",
      pdfLink: "",
      codeLink: "https://github.com/zalkikar/BBOX_GradCAM",
      pdfColor: "grey",
    },
  ],
};

export default content;