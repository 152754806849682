import React, { useEffect } from "react"
import { FaTwitter, FaGithub } from "react-icons/fa"
import { MdEmail } from "react-icons/md"
import { AiOutlineProfile } from "react-icons/ai";
import { TWITTER_LINK, EMAIL, GITHUB_LINK } from '../../constants'
import Link from "../Link"
import styled from 'styled-components'

const SubMenu = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
`

const SubMenuTitleWrap = styled.div`
    padding: 22px 8px;
`

const SubMenuTitle = styled.span`
    color: black; //#25adea;
    &:hover {
        color: #303030;
    }
`

const Socials = ({ iconColor }) => {
  const [windowDimenion, detectHW] = React.useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", detectSize);
    return () => {
      window.removeEventListener("resize", detectSize);
    };
  }, [windowDimenion]);
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", }}>
      <SubMenu>
        <SubMenuTitleWrap>
          <Link href={`${process.env.PUBLIC_URL + "/CV.pdf"}`} target="_blank">
            <SubMenuTitle>
              <AiOutlineProfile size={22} color={iconColor} />
            </SubMenuTitle>
          </Link>
        </SubMenuTitleWrap>
      </SubMenu>
      <SubMenu>
        <SubMenuTitleWrap>
          <div class="mailto"
            onClick={(e) => {
              window.location.href = `mailto:${EMAIL}`;
              e.preventDefault();
            }}
            style={{
              cursor: "pointer"
            }}>
            <SubMenuTitle>
              <MdEmail size={22} color={iconColor} />
            </SubMenuTitle>
          </div>
        </SubMenuTitleWrap>
      </SubMenu>
      <SubMenu>
        <SubMenuTitleWrap>
          <Link href={`${TWITTER_LINK}`} target="_blank">
            <SubMenuTitle>
              <FaTwitter size={22} color={iconColor} />
            </SubMenuTitle>
          </Link>
        </SubMenuTitleWrap>
      </SubMenu>
      <SubMenu>
        <SubMenuTitleWrap>
          <Link href={`${GITHUB_LINK}`} target="_blank">
            <SubMenuTitle>
              <FaGithub size={22} color={iconColor} />
            </SubMenuTitle>
          </Link>
        </SubMenuTitleWrap>
      </SubMenu>
    </div>
  )
}

export default Socials;