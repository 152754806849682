import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { theme } from "./Theme";
import { createGlobalStyle } from "styled-components";
import PersonalSite from "./components/pages/PersonalSite";

function App() {

  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") !== undefined &&
      localStorage.getItem("darkMode") !== null
      ? localStorage.getItem("darkMode")
      : false
  )

  const GlobalStyle = createGlobalStyle`
  *{
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px
      }
      ::-webkit-scrollbar-track {
        background-color: transparent;
      }
      ::-webkit-scrollbar-thumb {
        background-color: ${theme().colorAccent};
      }
  }
  body {
    background: ${theme(darkMode).bgGlobal};//black;
    font-family: 'Montserrat', sans-serif;
  }
`;

  const isMobile = () => {
    if (window.innerWidth <= 420) {
      return true
    } else {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
      ];
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    }
  };

  const app = () => (
    <div>
      <GlobalStyle />
      <Router>
        <Routes>
          <Route exact path={"/"} element={<PersonalSite darkMode={darkMode} setDarkMode={setDarkMode} />} />
          <Route path="*" element={<PersonalSite darkMode={darkMode} setDarkMode={setDarkMode} />} />
        </Routes>
      </Router>
    </div>
  );

  useEffect(() => {
    console.log("MODE", darkMode)
  }, [darkMode])

  if (!(isMobile())) {
    return app();
  } else {
    return app();
  }

};

export default App;